import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Step, StepConnector, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import moment from 'moment';

import CircleIcon from "@mui/icons-material/Circle";
import { deepPurple } from '@mui/material/colors';
import Images from '../../../assets/images/index'

import { PDFExport } from '@progress/kendo-react-pdf';
import OrderServices from '../../../api/orderServices/order.index';
import { domToPng } from 'modern-screenshot';
import axios from 'axios';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const useStyles = styled({
    blurredBackground: {
        filter: 'blur(12px)', // Apply the blur effect
        transition: 'filter 0.3s ease', // Smooth transition
    },
    popupImage: {
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
    },
    popupButton: {
        backgroundColor: '#00a8ff', // Same button color
        color: '#fff',
        marginTop: '20px',
    },
});

const steps = ['Pending', 'Processing', 'Confirmed', 'Delivered'];

const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${StepConnector.line}`]: {
        borderColor: theme.palette.primary.main,
    },
}));

const ColorStepLabel = styled(StepLabel)(({ theme, active, completed }) => ({
    '& .MuiStepLabel-label': {
        color: active || completed ? ` ${'black'} !important  ` : '#a1acb1',
    },
    '& .MuiStepIcon-root': {
        color: active || completed ? theme.palette.primary.main : 'white',
    },
}));
const OrderDetail = () => {

    const classes = useStyles();
    const { state } = useLocation()
    console.log(state, 'state');
    const navigate = useNavigate()
    const [rotateDirection, setRotateDirection] = useState(false)
    console.log(state);
    const [tshirtPrice, setTshirtPrice] = useState(0)
    console.log(state);
    const [open, setOpen] = useState(false);
    const [allData, setAllData] = useState(null)
    const pdfExportComponentFront = useRef(null);
    const pdfExportComponentBack = useRef(null);
    const elementFront = useRef(null)
    const elementBack = useRef(null)
    const parentRef = useRef(null);
    const divRef = useRef(null);
    const divRefBack = useRef(null);
    const [checked, setChecked] = React.useState(false);
    const [loader, setLoader] = useState(false)
    const [selectedSize, setSelectedSize] = useState()




    async function handleBase64ImageUpload(base64String,direction) {
        if (base64String) {
            const img = new Image();
            img.onload = function () {
                // uploadedImage = img
                // displayImagePreview(img);
            };
            img.src = base64String;
            console.log(base64String, 'uploadedImageuploadedImageuploadedImage');

            console.log(img, 'uploadedImageuploadedImageuploadedImage');

            await downloadImage(img,direction)
            console.log(base64String, 'uploadedImage');



        }
    }




    async function downloadImage(uploadedImage,direction) {
        console.log(uploadedImage, 'sadasasdas');

        if (!uploadedImage) {
            alert("Please upload an image first.");
            return;
        }

        // Create an offscreen canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // A4 size at 300 DPI: 8.27 x 300 px (width), 11.69 x 300 px (height)
        let a4Width
        let a4Height


        // Resize the uploaded image to fit the required size with the aspect ratio
        let scaleFactor  // Scale based on 4.17 inches width at 300 DPI


        if (selectedSize.toLowerCase() == 'small') {

            a4Width = 8.27 * 300;  // 8.27 inches at 300 DPI (width)
            a4Height = 11.69 * 300;  // 11.69 inches at 300 DPI (height)
            scaleFactor = 5.79 * 300 / uploadedImage.width;
        }
        else if (selectedSize.toLowerCase() == 'medium') {
            a4Width = 8.27 * 300;  // 8.27 inches at 300 DPI (width)
            a4Height = 11.69 * 300;  // 11.69 inches at 300 DPI (height)
            scaleFactor = 8.27 * 300 / uploadedImage.width;
        }
        else if (selectedSize.toLowerCase() == 'large') {
            a4Width = 11.69 * 300;  // 11.69 inches at 300 DPI (width)
            a4Height = 16.54 * 300; // 16.54 inches at 300 DPI (height)
            scaleFactor = 8.18 * 300 / uploadedImage.width;
        }
        else {
            a4Width = 11.69 * 300;  // 11.69 inches at 300 DPI (width)
            a4Height = 16.54 * 300; // 16.54 inches at 300 DPI (height)
            scaleFactor = 11.69 * 300 / uploadedImage.width;
        }
        // Set canvas to A4 size (300 DPI)
        canvas.width = a4Width;
        canvas.height = a4Height;
        const resizedWidth = uploadedImage.width * scaleFactor;
        const resizedHeight = uploadedImage.height * scaleFactor;
        // Calculate positioning with 2 inches padding from top and left
        const paddingLeft = 0.2 * 300;  // 2 inches to pixels at 300 DPI
        const paddingTop = 0.2 * 300;   // 2 inches to pixels at 300 DPI

        // Clear the canvas and draw the resized image
        ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear canvas before drawing
        ctx.drawImage(uploadedImage, paddingLeft, paddingTop, resizedWidth, resizedHeight);

        // Convert the canvas content to a data URL (PNG)
        const dataURL = canvas.toDataURL("image/png");

        // Create a download link and trigger the download
        const a = document.createElement("a");
        a.href = dataURL;
        console.log(dataURL);
        //   const url = await Base64Update(dataURL, 'front.png'); // Await Base64Update
        //   console.log(url);

        //   window.location.href = url; // Redirect to the updated URL
        //   downloadImage()
        a.download = `${state?.order_no}-${selectedSize}-${direction}-Design`;
        a.click();
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getPricing = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getPricing(params)
            console.log(data);
            setTshirtPrice(data?.pricing?.standard_price)


        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const [status, setStatus] = useState(1)
    useEffect(() => {
        if (state) {
            const index = steps.findIndex(step => step.toLowerCase() === state?.status?.toLowerCase());
            console.log(index);
            setStatus(index)
        }
        getPricing()
    }, [])

    const Base64Update = async (base64, name) => {
        let obj = {
            document: base64.split(',')[1],
            filename: name

        }

        try {
            const response = await axios.post('https://prod.printmeup.ai/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            return (response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const capture = async () => {
        setLoader(true)
        try {
            const div = divRef.current;
            if (!div) {

                return;
            }

            await waitForImagesToLoad(div); // Ensure images are loaded

            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // Adjust for image resolution

            // Set div styles to match A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';


            // Capture the screenshot
            const dataUrl = await domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                useCORS: true,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            });
            console.log(dataUrl, 'dataUrldataUrl');
            handleBase64ImageUpload(dataUrl,'Front')


        } catch (error) {

            setLoader(false)
        }
        finally {
            setLoader(false)
        }
    };


    const captureBack = async () => {
        setLoader(true)
        try {
            const div = divRefBack.current;
            if (!div) {

                return;
            }

            await waitForImagesToLoad(div); // Ensure images are loaded

            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // Adjust for image resolution

            // Set div styles to match A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';



            // Capture the screenshot
            const dataUrl = await domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                useCORS: true,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            });


            console.log(dataUrl, 'dataUrldataUrl');
            handleBase64ImageUpload(dataUrl,'Back')

        } catch (error) {
            setLoader(false)
        }
        finally {
            setLoader(false)
        }
    };


    const waitForImagesToLoad = () => {
        return new Promise((resolve) => {
            const imgElements = document.querySelectorAll('img'); // Update this selector as per your needs

            let loadedCount = 0;

            imgElements.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === imgElements.length) {
                            resolve();
                        }
                    };
                }
            });

            if (loadedCount === imgElements.length) {

                resolve();
            }
        });
    };

    return (
        <div >
            <Dialog slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: "blur(8px)",
                        backgroundColor: "transparent",
                    }

                },


            }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        borderRadius: '12px'
                    }
                }} maxWidth={'lg'} sx={{ borderRadius: '12px' }} open={open} onClose={handleClose}>

                <DialogContent className={classes.blurredBackground} sx={{ backgroundColor: '#0F172A', overflow: 'hidden' }}>
                    <Grid container justifyContent={'space-around'}>

                        <Button onClick={() => {
                            if (!loader) {
                                capture()
                            }
                        }} sx={{ textTransform: 'capitalize', border: '1px solid #0EA5EA', width: '180px', height: "40px" }}>{loader ? <CircularProgress size={15} /> : 'Download Front Design'}</Button>
                        <Button onClick={() => {
                            if (!loader) {
                                captureBack()
                            }
                        }} sx={{ textTransform: 'capitalize', border: '1px solid #0EA5EA', width: '180px', height: "40px" }}>{loader ? <CircularProgress size={15} /> : 'Download Back Design'}</Button>
                    </Grid>
                    <Grid container >

                        {allData && <Grid component={'div'} className={`  tshirt-img-scale2`} ref={elementFront} item lg={6} md={12} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important', position: 'relative' }}>
                            <div style={{ position: 'relative' }}>

                                <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color : 'white']} />
                                <div style={{ position: 'absolute', top: 90, left: 145, backgroundColor: 'rgba(0,0,0,0)' }}>
                                    {!rotateDirection && <PDFExport ref={pdfExportComponentFront} paperSize="A4" scale={2.7} fileName="Frontprint">
                                        <div ref={divRef} style={{ backgroundColor: 'transparent' }} id='pdfFront'>
                                            <div className=' pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                                {allData?.frontText && allData.frontText.length > 0 && allData.frontText.map((item, index) => {
                                                    console.log(item?.fontSize, 'dedede');
                                                    const textStyle = {
                                                        fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                        fontWeight: item.fontWeight,
                                                        fill: item.color,
                                                        fontFamily: item.fontFamily,

                                                    };

                                                    const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${2 * (item.width / 3)},0`;
                                                    const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                    const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                    const shapePaths = {
                                                        circlePath,
                                                        ellipsePath,
                                                        wavePath,
                                                        straightPath,
                                                    };

                                                    const selectedPath = shapePaths[item.shape] || '';
                                                    const uniqueId = `front-circlePath${index}`;

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div
                                                                id={index}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: `${item.width}px`,
                                                                    height: `${item.height}px`,
                                                                    top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                    left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                    zIndex: item.zIndexes,
                                                                    backgroundImage: `url('${item.url}')`,
                                                                    backgroundSize: 'contain',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundPosition: 'center'
                                                                }}
                                                            >


                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}


                                                {allData?.frontPic.length > 0 && allData?.frontPic.map((imageData, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Grid
                                                                component={'div'}
                                                                className={`Design-${index}`}
                                                                style={{
                                                                    clipPath: imageData.clipPath,
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    backgroundImage: `url(${imageData.imageUrl}), url(https://corsproxy.io/?url=${imageData.imageUrl})`,
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    borderRadius: `${imageData.borderRadius}px`,
                                                                    transform: `${imageData.rotation}`,
                                                                    zIndex: imageData?.zIndexes,
                                                                }}
                                                                onError={(e) => {
                                                                    // Fallback to direct image URL if the proxy fails
                                                                    e.target.style.backgroundImage = `url(${imageData.imageUrl})`;
                                                                }}
                                                            />
                                                            {/* Add overlay image */}
                                                            {checked && <Grid
                                                                component={'div'}
                                                                className={`Overlay-${index}`}
                                                                style={{
                                                                    width: `${imageData.width}px`,
                                                                    height: `${imageData.height}px`,
                                                                    position: 'absolute',
                                                                    top: `${imageData.top}px`,
                                                                    left: `${imageData.left}px`,
                                                                    backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                                    backgroundSize: 'contain',
                                                                    backgroundPosition: 'center',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    opacity: 0.5, // Adjust the transparency of the overlay image
                                                                    zIndex: imageData?.zIndexes + 1 // Place overlay above the image
                                                                }}
                                                            />}
                                                        </div>
                                                    );
                                                })}


                                            </div>
                                        </div>
                                    </PDFExport>}

                                </div>
                            </div></Grid>}

                        {allData && <Grid item lg={6} md={12} className={`  tshirt-img-scale2`} ref={elementBack} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important' }}> <div style={{ position: 'relative' }}>
                            <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color + "Back" : 'white' + "Back"]} />
                            <div style={{ position: 'absolute', top: 90, left: 145 }}>

                                <PDFExport ref={pdfExportComponentBack} paperSize="A4" scale={2.7} fileName="Backprint">
                                    <div ref={divRefBack} style={{ backgroundColor: 'transparent' }} id='pdfBack'>
                                        <div className='pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                            {allData?.backText && allData?.backText.length > 0 && allData.backText.map((item, index) => {
                                                const textStyle = {

                                                    fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                    fontWeight: item.fontWeight,
                                                    fill: item.color,
                                                    fontFamily: item.fontFamily,

                                                };
                                                const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${item.width / 3},0`;
                                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                const shapePaths = {
                                                    circlePath,
                                                    ellipsePath,
                                                    wavePath,
                                                    straightPath,
                                                };

                                                const selectedPath = shapePaths[item.shape] || '';
                                                const uniqueId = `back-circlePath${index}`;

                                                return (
                                                    <React.Fragment key={index}>

                                                        <div
                                                            id={`back${index}`}
                                                            style={{
                                                                position: 'absolute',
                                                                width: `${item.width}px`,
                                                                height: `${item.height}px`,
                                                                top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                transform: `rotate(${item.rotation}deg)`,
                                                                zIndex: item.zIndexes,
                                                                backgroundImage: `url('${item.url}')`,
                                                                backgroundSize: 'contain',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center'
                                                            }}>

                                                        </div>

                                                    </React.Fragment>
                                                );
                                            })}

                                            {allData?.backPic.length > 0 && allData?.backPic.map((imageUrl, index) => (
                                                <div key={index}>
                                                    <Grid
                                                        component={'div'}
                                                        className={`Design2-${index}`}
                                                        style={{
                                                            clipPath: allData?.backPic[index].clipPath,
                                                            width: `${allData?.backPic[index].width}px`,
                                                            height: `${allData?.backPic[index].height}px`,
                                                            position: 'absolute',
                                                            backgroundImage: `url(https://corsproxy.io/?url=${imageUrl?.imageUrl}), url(${imageUrl?.imageUrl})`,
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            top: `${allData?.backPic[index].top}px`,
                                                            left: `${allData?.backPic[index].left}px`,
                                                            borderRadius: `${allData?.backPic[index].borderRadius}px`,
                                                            transform: `${allData?.backPic[index].rotation}`,
                                                            zIndex: imageUrl?.zIndexes,
                                                        }}
                                                        onError={(e) => {
                                                            // Handle image fallback if the proxy fails
                                                            e.target.style.backgroundImage = `url(${imageUrl?.imageUrl})`;
                                                        }}
                                                    />
                                                    {false && <Grid
                                                        component={'div'}
                                                        className={`Overlay2-${index}`}
                                                        style={{
                                                            width: `${imageUrl.width}px`,
                                                            height: `${imageUrl.height}px`,
                                                            position: 'absolute',
                                                            top: `${imageUrl.top}px`,
                                                            left: `${imageUrl.left}px`,
                                                            backgroundImage: `url(${allData?.color == 'white' ? Images?.toneWhite : allData?.color == 'black' ? Images?.toneBlack : allData?.color == 'lightgreen' ? Images?.toneGreen : allData?.color == 'darkgreen' ? Images?.toneDarkGreen : allData?.color == 'blue' ? Images?.toneBlue : allData?.color == 'yellow' ? Images?.toneOrange : allData?.color == 'purple' ? Images?.tonePurple : 'white'})`, // Path to the overlay image
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            opacity: 0.5, // Adjust the transparency of the overlay image
                                                            zIndex: imageUrl?.zIndexes + 1 // Place overlay above the image
                                                        }}
                                                    />}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </PDFExport>
                            </div>
                        </div></Grid>}
                    </Grid>
                </DialogContent>

            </Dialog>

            <Box sx={{ width: '100%', margin: '0 auto', borderRadius: '12px', mt: "80px" }} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
                    <Box>
                        <Box sx={{ color: 'black', fontSize: '16px' }}>Order ID</Box>
                        <Box component={'div'} className='heading-font' sx={{ fontWeight: 700, fontSize: '24px' }}>{state?.order_no}</Box>
                    </Box>
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                padding: "5px 65px",
                                borderRadius: "8px",
                                textTransform: "none",
                                fontSize: "16px",
                                fontWeight: "bold",
                                backgroundColor: state?.status == 'delivered' ? "#143D39" : '#5A4A1E', // Dark green background
                                border: state?.status == 'delivered' ? "2px solid #3FBF43" : "2px solid #D4AF37", // Green border
                                color: "#FFFFFF", // White text

                            }}
                        >
                            <CircleIcon sx={{ fontSize: 12, color: "white" }} />
                            <Typography sx={{ textTransform: 'capitalize' }}>{state?.status}</Typography>
                        </Button>
                    </Box>
                </Box>
                <Grid container gap={2}>
                    <Grid item lg={5} p={3} mt={4} sx={{ cursor: 'pointer', backgroundColor: 'white', color: 'black !important', boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: "12px" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '96%' }}>

                            <Box component={'div'} className='heading-font2' sx={{ fontWeight: 400, color: 'black !important' }}>Order Details</Box>

                        </Box>
                        {console.log(state)}
                        <Divider style={{ width: '100%', borderColor: '#B0B0B04D', marginTop: '20px' }} />
                        <Grid container sx={{ mb: 2, mt: 2 }} alignItems={'center'} >

                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Designs</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.designs?.length}</Grid>

                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Quantity</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.total_qty}</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Total Pricing</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.total_amount}</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Payment Method</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Card</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>Promo Code</Grid>
                            <Grid item xs={3} sx={{ color: 'black', fontWeight: 600, fontSize: '14px' }} mt={2}>{state?.promo_code}</Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={5} p={3} mt={4} sx={{ cursor: 'pointer', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: "12px" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '96%' }}>

                            <Box component={'div'} className='heading-font2' sx={{ fontWeight: 400, color: 'black !important' }}>Order Summary</Box>

                        </Box>

                        <Divider style={{ width: '100%', borderColor: '#B0B0B04D', marginTop: '20px' }} />
                        <Grid container>
                            <Grid item sm={12} md={12} sx={{ borderRadius: '12px' }}>
                                <Box >

                                    <div style={{ color: '#fff' }}>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', color: 'black', fontWeight: 600, fontSize: '14px' }}>
                                            <span>Items ({state?.total_qty})</span>
                                            <span>AED {state?.sub_amount}</span>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: 'black', fontWeight: 600, fontSize: '14px' }}>
                                            <span>Shipping :</span>
                                            <span>AED {state?.delivery_charges}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: 'black', fontWeight: 600, fontSize: '14px' }}>
                                            <span>Discount :</span>
                                            <span>AED {state?.promo_discount || 0}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: 'black', fontWeight: 600, fontSize: '14px' }}>
                                            <span>Sub Total :</span>
                                            <span>AED {parseFloat(parseFloat(state?.sub_amount) + parseFloat(state?.delivery_charges)) - parseFloat(state?.promo_discount || 0)}</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: 'black', fontWeight: 600, fontSize: '14px' }}>
                                            <span>VAT 5% :</span>
                                            <span>
                                                AED {state?.vat}
                                            </span>
                                        </div>
                                        <hr style={{ borderColor: '#fff', margin: '20px 0' }} />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', color: 'black' }}>
                                            <span>Order Total:</span>
                                            <span>AED {state?.total_amount}</span>
                                        </div>
                                        <hr style={{ borderColor: '#fff', margin: '20px 0' }} />

                                    </div>
                                </Box>

                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container mt={4}>
                        <Box component={'div'} className='heading-font' sx={{ fontWeight: 700, fontSize: '24px' }}>Items</Box>
                    </Grid>
                    <Grid container gap={2}>
                        {state?.details?.length > 0 ? (
                            state?.details.map((item, index) => (

                                <Grid
                                    item
                                    lg={5}
                                    md={5}
                                    sm={12}
                                    xs={12}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    gap={2}
                                    p={2}
                                    sx={{ backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: "12px" }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '100px',
                                            height: 'auto',
                                            '&:hover .overlay': {
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{

                                                backgroundColor: '#0BD1D14D',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={item.generated_art} // Use item property correctly
                                                alt="Item"
                                                sx={{
                                                    width: '80px',
                                                    height: 'auto',
                                                    borderRadius: 1,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            className="overlay"
                                            component={'div'}
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',  // semi-transparent overlay
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                opacity: 0,
                                                transition: 'opacity 0.3s ease',
                                                borderRadius: 1,
                                                color: 'white',
                                                cursor: 'pointer'
                                            }}

                                            onClick={() => { setOpen(true); setAllData(state?.designs[item?.design_index]); setSelectedSize(item?.size) }}
                                        >
                                            <Button

                                                sx={{ color: 'white', textTransform: 'none' }}
                                            >
                                                Preview
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Item Details */}
                                    <Box>
                                        <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                            Age Group: <span style={{ color: 'black' }}>{item.size_type || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                            Size: <span style={{ color: 'black' }}>{item.size || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                            Shirt Quality: <span style={{ color: 'black', textTransform: 'capitalize' }}>{item.shirt_type || 'N/A'}</span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'black', mt: 1 }}>
                                            Print Quality: <span style={{ color: 'black', textTransform: 'capitalize' }}>{item.print_intensity || 'N/A'}</span>
                                        </Typography>
                                    </Box>
                                </Grid>

                            ))
                        ) : (
                            <Typography sx={{ color: 'white', textAlign: 'center' }}>
                                Your cart is empty.
                            </Typography>
                        )}

                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default OrderDetail
