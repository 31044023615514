import { UserRoutes } from "./route";
import { post, get, put, patch } from "../index";


const UserServices = {
  getUsers: async (page ,limit,search) => {
    let result = get(UserRoutes.getUsers + `?page=${page}&limit=${limit}&search=${search}`  );
    return result;
  },


  getDesign: async (id) => {
    let result = get(UserRoutes.getDesigns + `?user_id=${id} `);
    return result;
  },
  updateUser: async (obj) => {
    let result = patch(UserRoutes.updateUser ,obj);
    return result;
  },

};
export default UserServices;
